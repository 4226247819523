import '../index.css'
import quote from "../assets/quote.svg";

function QuoteCard({ text, name }) {
  return (
    <div className="card-testimonial">
      <img src={quote} loading="lazy" alt="" className="image-quote" />
      <p className="paragraph">
        <em>{text}</em>
      </p>
      <div className="testimonial-author-wrap">
        <div>
          <p className="paragraph"><strong>{name}</strong></p>
        </div>
      </div>
    </div>
  );
}
export default QuoteCard;
