import "../index.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";

import Footer from "../components/footer";
import NavBarOther from "../components/navBarOther";

function SuccesPurchase() {
  const navigate = useNavigate();
  const [customer, setCustomer] = useState("");
  const [searchParams] = useSearchParams();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    getCustomerObject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isReady) {
      if (!(localStorage.getItem("session") === `${searchParams.get("session_id")}`)) {
        sendEmail();
        updateQuantity();
        localStorage.setItem("session", `${searchParams.get("session_id")}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  async function getCustomerObject() {
    try {
      const response = await axios.get(
        `https://api.stripe.com/v1/checkout/sessions/${searchParams.get(
          "session_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          },
        }
      );
      const customer_data = response.data.customer_details;
      setCustomer(customer_data);
      setIsReady(true);
    } catch {
      navigate("/");
    }
  }

  async function updateQuantity() {
    try {
      const response = await axios.get(
        `https://api.stripe.com/v1/products/${process.env.REACT_APP_STRIPE_PRODUCT_KEY}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          },
        }
      );
      try {
        const get = parseInt(response.data.metadata.amount);
        const post = get - 1;
        await axios.post(
          `https://api.stripe.com/v1/products/${process.env.REACT_APP_STRIPE_PRODUCT_KEY}`,
          {
            metadata: { amount: `${post}` },
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  }


  function sendEmail() {
    var templateParams = {
      name: `${customer.name}`,
      email: `${customer.email}`,
    };
    emailjs
      .send(
        "service_12i2toy",
        "template_qr0ouoe",
        templateParams,
        "ljTLNK15hzcbKuFpl"
      )
      .then(
        (result) => {
          
        },
        (error) => {
          console.log(error);
        }
      );
  }

  return (
    <>
      <NavBarOther />
      <div className="section-white blob-haikei">
        <div className="error-page">
          <div className="container-content gutter-outside">
            <div className="text-top-title-blue">All set</div>
            <div className="text-center">
              <h2 className="text-title-black">
                Thank you {customer.name}, your purchase was processed
                successfully
              </h2>
            </div>
            <div className="wraper-text-successful">
              <p className="paragraph black pricing">
                Thank you for signing up, I'm looking forward to working
                togheter! A receipt and instructions on how to get started are
                sent to {customer.email}. To manage your subscription, you'll be
                able to log in to your <a
                href="https://billing.stripe.com/p/login/aEU29sbqf3lM1DGfYY"
                target="_blank"
                rel="noreferrer"
                className="link-introduction-text"
              >
                <strong>account</strong>
              </a> using that same e-mail.
              </p>
            </div>
            <div className="back-home">
              <button
                onClick={() => navigate("/")}
                className="submit-button-form submit-button not-found"
              >
                Head to homepage
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SuccesPurchase;
