import "../index.css";
import { useNavigate } from "react-router-dom";

import Footer from "../components/footer";
import NavBarOther from "../components/navBarOther";

function TermsOfService() {
  const navigate = useNavigate();

  return (
    <>
      <NavBarOther />
      <div className="section-black">
        <div className="error-page">
          <div className="container-content gutter-outside">
            <div className="text-center">
              <h2 className="text-title-white align-right">Terms of Service</h2>
            </div>
            <div className="divider-pricing" />
            <p className="paragraph">
              By using this website or purchasing the coaching service, you
              agree to the following terms:
            </p>
            <div className="benefits-pricing">
            <ul>
              <li className="list-item-pricing legal">
                You acknowledge that the coaching service is for educational and
                informational purposes only and is not intended to be investment
                or trading advice.
              </li>
              <li className="list-item-pricing legal">
                You accept all risks associated with using the information
                provided on this website or through the coaching service,
                including the risk of financial loss.
              </li>
              <li className="list-item-pricing legal">
                You will not hold me responsible for any losses you may incur as
                a result of using the information provided on this website or
                through the coaching service.
              </li>
              <li className="list-item-pricing legal">
                You will not reproduce, distribute, or publicly display any
                content from this website or the coaching service without my
                written permission.
              </li>
              <li className="list-item-pricing legal">
                All sales are final and there is no money-back guarantee for the
                coaching service.
              </li>
              <li className="list-item-pricing legal">
                I reserve the right to terminate or suspend your access to this
                website or the coaching service at any time, for any reason, and
                without notice.
              </li>
            </ul>
            </div>
            <div className="back-home">
              <button
                onClick={() => navigate("/")}
                className="submit-button-form submit-button not-found"
              >
                Head to homepage
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermsOfService;
