import '../index.css'

function HowRow({ imageDivId, imageId, divCardId, image, text }) {
  return (
    <>
      <div id={imageDivId} className="center-how-image">
        <img
          src={image}
          loading="lazy"
          width={115}
          id={imageId}
          sizes="(max-width: 479px) 70vw, 115px"
          alt="text bubble"
          className="image-how"
        />
      </div>
      <div id={divCardId} className="how-card">
        <div className="text-how-card">
          <div className="text-how">{text}</div>
        </div>
      </div>
    </>
  );
}
export default HowRow;
