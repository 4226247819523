import "../index.css";
import { useNavigate } from "react-router-dom";

import Footer from "../components/footer";
import NavBarOther from "../components/navBarOther";

function PrivacyPolicy() {
  const navigate = useNavigate();

  return (
    <>
      <NavBarOther />
      <div className="section-black">
        <div className="error-page">
          <div className="container-content gutter-outside">
            <div className="text-center">
              <h2 className="text-title-white align-right">Privacy Policy</h2>
            </div>
            <div className="divider-pricing" />
            <p className="paragraph">
            I respect your privacy and will only collect, use, and share your personal information as described in this Privacy Policy.
            </p>
            <div className="benefits-pricing">
            <ul>
              <li className="list-item-pricing">
              I collect personal information such as your name, email address, and payment information when you purchase the coaching service or sign up for my email list.
              </li>
              <li className="list-item-pricing">
              I use this information to provide you with the coaching service and communicate with you about your account and the service.
              </li>
              <li className="list-item-pricing">
              I do not sell or share your personal information with third parties, except as required by law or to protect the security of my website and the coaching service.
              </li>
              <li className="list-item-pricing">
              I may use third-party service providers to help me operate my business and deliver the coaching service, such as payment processors and email marketing platforms.
               These service providers may have access to your personal information in the course of providing their services, but they are not allowed to use it for any other purpose.
              </li>
              <li className="list-item-pricing">
              I use appropriate security measures to protect your personal information, but please be aware that no method of transmission over the internet or method of electronic storage is completely secure.
              </li>
              <li className="list-item-pricing">
              I may update this Privacy Policy from time to time. Any changes will be posted on this page and I encourage you to review the policy periodically.
              </li>
            </ul>
            </div>
            <div className="back-home">
              <button
                onClick={() => navigate("/")}
                className="submit-button-form submit-button not-found"
              >
                Head to homepage
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
