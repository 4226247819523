import "../index.css";
import logo from "../assets/logo-transparant-horizontal.png";
import twitter from "../assets/twitter.png";
import { Link } from "react-router-dom";

import EmailContactForm from "../components/emailContactForm";

function Footer() {
  return (
    <div id="contact" className="section-black gradient-blue-background">
      <div className="container-content gutter-outside">
        <div className="layout-grid grid-contact">
          <div id="contact-footer" className="wraper-contact-text">
            <h2 className="text-title-white align-right">Get in touch</h2>
            <div className="text-contact">
              Feel free to ask or discuss anything that comes to mind. Fill in
              the form and I will get back to you within 24 hours.
            </div>
            <div className="text-contact">
              If preferable, please feel free to write me at
              <a
                className="link-mail"
                href="mailto:contact@tradingcoachmichael.com"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                contact@tradingcoachmichael.com
              </a>
            </div>
          </div>
          <EmailContactForm />
        </div>
        <div className="footer">
          <div className="wrap-footer">
            <div className="layout-grid grid-footer">
              <div id="left-footer" className="left-footer">
                <img
                  onClick={() =>  window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}
                  src={logo}
                  loading="lazy"
                  width={125}
                  sizes="(max-width: 479px) 88vw, 100px"
                  alt="Logo transparant"
                  className="logo-image"
                />
                <div className="divider-footer" />
                <div className="text-brand-footer">~Michael~</div>
              </div>
              <div id="mid-footer" className="mid-footer">
                <div className="text-inc-footer">
                  © 2023
                </div>
              </div>
              <div id="right-footer" className="right-footer">
                <a
                  href="https://mobile.twitter.com/tawithmichael"
                  target="_blank"
                  rel="noreferrer"
                  className="link-twitter-footer inline-block"
                >
                  <img
                    src={twitter}
                    loading="lazy"
                    alt="Twitter icon"
                    className="image-twitter"
                  />
                </a>
              </div>
            </div>
            <div className="layout-grid grid-footer legal-terms">
              <div id="left-footer" className="left-footer">
                <Link to="/terms-of-service" className="link-footer-or-header" target="_blank">
                  <p className="text-inc-footer">Terms of Service</p>
                </Link>
              </div>
              <div id="mid-footer" className="mid-footer">
                <Link to="/disclaimer-disclosure" className="link-footer-or-header" target="_blank">
                  <p className="text-inc-footer">Disclaimer/Disclosure</p>
                </Link>
              </div>
              <div id="right-footer" className="right-footer">
                <Link to="/privacy-policy" className="link-footer-or-header" target="_blank">
                  <p className="text-inc-footer">Privacy Policy</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
