import "../index.css";
import { Link } from "react-scroll";
import logo from "../assets/logo-transparant-horizontal.png";

import NavItem from "./navItem";

function NavBarHome() {
  return (
    <div className="nav">
      <div className="container-content gutter-outside navbar">
        <div className="container-header">
          <div className="header-left">
            <Link
              className="link-logo"
              to="home"
              spy={true}
              smooth={true}
              duration={750}
            >
              <img
                src={logo}
                loading="lazy"
                width={100}
                sizes="(max-width: 479px) 50px, 100px"
                alt="Logo transparant"
                className="logo-image"
              />
            </Link>
          </div>
          <div className="header-right">
            <NavItem to="benefits" text="Benefits" />
            <NavItem to="how" text="How" />
            <NavItem to="pricing" text="Pricing" />
            <NavItem to="contact" text="Contact" />
            <a
              href="https://billing.stripe.com/p/login/aEU29sbqf3lM1DGfYY"
              className="header-link inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <div className="header-text">Account</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default NavBarHome;
