import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Loader from "react-spinners/PulseLoader";

import succes from "../assets/succes.png"
import fail from "../assets/fail.png"
 
const EmailContactForm = () => {

 const form = useRef();
 const [message, setMessage] = useState(null)
 const [messageImage, setMessageImage] = useState(null)
 const [name, setName] = useState("");
 const [email, setEmail] = useState("");
 const [text, setText] = useState("");
 const [loading, setLoading] = useState(false);
 
 const sendEmail = (e) => {
   e.preventDefault();
   setLoading(true);
   emailjs.sendForm('service_12i2toy', 'template_zua9rn8', form.current, 'ljTLNK15hzcbKuFpl')
     .then((results) => {
      setLoading(false);
      setMessage("Message sent successfully!")
      setMessageImage(succes)
      setName("");
      setEmail("");
      setText("");
     }, (error) => {
      setLoading(false);
      setMessage("An error occured sending this message, please try again.")
      setMessageImage(fail)
      console.log(error)
    });
 };

 const handleChangeName = (e) => {
  setName(e.target.value);
};
 const handleChangeEmail = (e) => {
  setEmail(e.target.value);
};
 const handleChangeText = (e) => {
  setText(e.target.value);
};

  return (
    <div className="contact-form">
      <form
        id="email-form"
        name="email-form"
        data-name="Email Form"
        method="get"
        className="content-form"
        ref={form}
        onSubmit={sendEmail}
      >
        <label htmlFor="name" className="text-label-form">
          Your Name
        </label>
        <input
          type="text"
          className="text-field-form contact-input"
          maxLength={256}
          name="name"
          data-name="Name"
          id="name"
          value={name}
          onChange={(e) => handleChangeName(e)}
          required
        />
        <label htmlFor="Email-3" className="text-label-form">
          Your Email
        </label>
        <input
          type="email"
          className="text-field-form contact-input"
          maxLength={256}
          name="Email"
          data-name="Email"
          id="Email"
          value={email}
          onChange={(e) => handleChangeEmail(e)}
          required
        />
        <label htmlFor="Message" className="text-label-form">
          Your Message
        </label>
        <textarea
          name="Message"
          maxLength={5000}
          id="Message-2"
          required
          data-name="Message"
          className="text-field-form big contact-input"
          value={text}
          onChange={(e) => handleChangeText(e)}
        />
        <div className='wraper-submit-and-loader'>
          <input
            type="submit"
            value="Send message"
            data-wait="Please wait..."
            className="submit-button-form submit-button not-found"
          />
          <Loader 
          className='loader-contact-form'
          color="white"
          loading={loading}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
          />
        </div>
        {message && 
        <div className='succes-or-fail-form'>
          <img className='status-image' alt='status icon' src={messageImage}/>
          <p className='paragraph'>{message}</p>
        </div>
        } 
      </form>
    </div>
  );
};

export default EmailContactForm;
