import "../index.css";
import axios from "axios";
import { Link } from "react-scroll";

import arrowLeft from "../assets/arrow-left.png";
import { useEffect, useState } from "react";

import EmailNotifyMe from "../components/emailNotifyMe";

function CheckoutCard() {
  const [quantity, setQuantity] = useState(0);
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    updateQuantity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateQuantity() {
    axios
      .get(
        `https://api.stripe.com/v1/products/${process.env.REACT_APP_STRIPE_PRODUCT_KEY}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          },
        }
      )
      .then((response) => {
        const maxQuantity = response.data.metadata.maxAmount;
        setMaxQuantity(maxQuantity);
        const quantity = response.data.metadata.amount;
        setQuantity(quantity);
      })
      .catch((error) => console.log(error));
  }


  async function checkQuantityCheckout() {
    axios
      .get(
        `https://api.stripe.com/v1/products/${process.env.REACT_APP_STRIPE_PRODUCT_KEY}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          },
        }
      )
      .then((response) => {
        const availableQuantity = response.data.metadata.amount;
        setQuantity(availableQuantity);
        setClicked((current) => !current);
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    if (!(quantity <= 0)) {
      window.location.href = "https://buy.stripe.com/4gwdUdch0fyX1NucMN";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clicked]);

  return (
    <div className="pricing-card">
      <div className="title-pricing">
        <div className="header-title-pricing">
          <h3 className="text-title-pricing-left">1-on-1 Coaching</h3>

          <h3 className="text-title-pricing-right">
            available: {quantity}/{maxQuantity}
            <Link
              to="text-limited"
              className="link-pricing"
              spy={true}
              smooth={true}
              duration={750}
              offset={-400}
            >
              <span className="span-star-text">*</span>
            </Link>
          </h3>
        </div>
        <div className="text-sub-title-pricing">
          Everything you'll ever need
        </div>
      </div>
      <div className="divider-pricing" />
      <div className="price-pricing">
        <h3 className="text-price-pricing">$ 199.00</h3>
        <div className="wraper-month-pricing">
          <div className="text-month-pricing">US/</div>
          <div className="text-month-pricing">mo</div>
        </div>
      </div>
      {quantity <= 0 ? (
        <div>
          <p className="paragraph">
          Sorry, our 1-on-1 Coaching is currently out of stock. Please sign up to get notified when 1-on-1 Coaching becomes available and when new products launch:
          </p>
          <EmailNotifyMe />
        </div>
      ) : (
        <div className="purchase-button-pricing">
          <button
            onClick={checkQuantityCheckout}
            className="link-purchase-pricing inline-block"
          >
            <div className="wraper-purchase-pricing">
              <div className="text-purchase-pricing">Purchase</div>
              <img
                src={arrowLeft}
                loading="lazy"
                height={17}
                width={17}
                alt="arrow side white"
                className="image-arrow-pricing"
              />
            </div>
          </button>
        </div>
      )}
      <div className="divider-pricing" />
      <div className="benefits-pricing">
        <ul>
          <li className="list-item-pricing">
            Develop a proper trading strategy that fits your style
          </li>
          <li className="list-item-pricing">
            Increase your knowledge on important topics
          </li>
          <li className="list-item-pricing">
            Receive market updates and trading plans
          </li>
          <li className="list-item-pricing">
            Coaching fitted to your personal needs
          </li>
          <li className="list-item-pricing">
            Improve your trading skills and habits
          </li>
          <li className="list-item-pricing">
            Get feedback on trades and plans
          </li>
          <li className="list-item-pricing">Ask anything, anytime</li>
          <li className="list-item-pricing">Unlimited chat time</li>
        </ul>
      </div>
    </div>
  );
}
export default CheckoutCard;
