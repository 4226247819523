import "../index.css";
import { useNavigate } from "react-router-dom";

import Footer from "../components/footer";
import NavBarOther from "../components/navBarOther";

function Disclaimer() {
  const navigate = useNavigate();

  return (
    <>
      <NavBarOther />
      <div className="section-black">
        <div className="error-page">
          <div className="container-content gutter-outside">
            <div className="text-center">
              <h2 className="text-title-white">Disclaimer and Disclosure</h2>
            </div>
            <div className="divider-pricing" />
            <p className="paragraph">
            The information provided on this website and through the coaching service is for educational and informational purposes only. It is not intended to be investment or trading advice, nor is it a recommendation to buy or sell any security or financial instrument. I am not a licensed financial adviser and I am not providing individualized advice. The decision to trade or invest is ultimately yours and you should seek the advice of a licensed financial professional before making any decisions.
I am not responsible for any losses you may incur as a result of using the information provided on this website or through the coaching service. Trading and investing involve significant risk and can result in the loss of your entire investment. Past performance is not necessarily indicative of future results.
Testimonials appearing on this website may not be representative of the experience of other clients or customers and is not a guarantee of future performance or success.
            </p>
            <div className="back-home">
              <button
                onClick={() => navigate("/")}
                className="submit-button-form submit-button not-found"
              >
                Head to homepage
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Disclaimer;
