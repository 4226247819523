import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Loader from "react-spinners/PulseLoader";

import succes from "../assets/succes.png"
import fail from "../assets/fail.png"
 
const EmailNotifyMe = () => {

 const form = useRef();
 const [message, setMessage] = useState(null)
 const [messageImage, setMessageImage] = useState(null)
 const [email, setEmail] = useState("");
 const [loading, setLoading] = useState(false);
 
 const sendEmail = (e) => {
   e.preventDefault();
   setLoading(true);
   emailjs.sendForm('service_12i2toy', 'template_zua9rn8', form.current, 'ljTLNK15hzcbKuFpl')
     .then((result) => {
      setLoading(false);
      setMessage("Notification set successfully!")
      setMessageImage(succes)
      setEmail("");
     }, (error) => {
      setLoading(false);
      setMessage("An error occured with enabling notifications, please try again.")
      setMessageImage(fail)
      console.log(error)
    });
 };

 const handleChangeEmail = (e) => {
  setEmail(e.target.value);
};

  return (
    <div className="contact-form">
      <form
        id="email-form"
        name="email-form"
        data-name="Email Form"
        method="get"
        className="content-form"
        ref={form}
        onSubmit={sendEmail}
      >
        <input
          type="email"
          className="text-field-form contact-input"
          maxLength={256}
          name="Email"
          data-name="Email"
          id="Email"
          placeholder='Email'
          value={email}
          onChange={(e) => handleChangeEmail(e)}
          required
        />
        <div className='wraper-submit-and-loader'>
          <input
            type="submit"
            value='Notify me'
            data-wait="Please wait..."
            className="submit-button-form submit-button not-found"
          />
          <Loader 
          className='loader-contact-form'
          color="white"
          loading={loading}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
          />
        </div>
        {message && 
        <div className='succes-or-fail-form'>
          <img className='status-image' alt='status icon' src={messageImage}/>
          <p className='paragraph'>{message}</p>
        </div>
        } 
      </form>
    </div>
  );
};

export default EmailNotifyMe;
