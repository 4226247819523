import "../index.css";
import { useNavigate } from "react-router-dom";

import Footer from "../components/footer";
import NavBarOther from "../components/navBarOther";

function ErrorPage() {
  const navigate = useNavigate();

  return (
    <>
      <NavBarOther />
        <div className="section-black not-found-code">
        <div className="error-page">
          <div className="container-content gutter-outside">
            <div className="text-top-title-blue">Page not found</div>
            <div className="text-center">
              <h2 className="text-title-white">
                Oops! This isn&apos;t the page you&apos;re looking for
              </h2>
            </div>

            <p className="paragraph black">
              Head back, or move along to the homepage to find a new way
              forward.
            </p>
            <div className="back-home">
              <button
                onClick={() => navigate("/")}
                className="submit-button-form submit-button not-found"
              >
                Head to homepage
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ErrorPage;
