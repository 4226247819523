import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import Home from './pages/home';
import ErrorPage from './pages/notFound';
import SuccesPurchase from './pages/succesPurchase';
import TermsOfService from './pages/termsOfService';
import Disclaimer from './pages/disclaimer';
import PrivacyPolicy from './pages/privacyPolicy';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />
  },
  {
    path: "/succesfull-purchase-thank-you",
    element: <SuccesPurchase />,
    errorElement: <ErrorPage />
  },
  {
    path: "/terms-of-service",
    element: <TermsOfService />,
    errorElement: <ErrorPage />
  },
  {
    path: "/disclaimer-disclosure",
    element: <Disclaimer />,
    errorElement: <ErrorPage />
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    errorElement: <ErrorPage />
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
