import "../index.css";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Pagination } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import arrowDown from "../assets/arrow-down.png";
import brush from "../assets/brush.png";
import clock from "../assets/clock.png";
import stars from "../assets/trustpilot.svg";
import textBubble from "../assets/text-bubble.png";

import BenefitCard from "../components/benefitCard";
import StayUpdatedCard from "../components/stayUpdatedCard";
import QuoteCard from "../components/quoteCard";
import HowRow from "../components/howRow";
import CheckoutCard from "../components/checkoutCard";
import NavBarHome from "../components/navBarHome";
import Footer from "../components/footer";

function Home() {
  const [maxQuantity, setMaxQuantity] = useState(0);

  useEffect(() => {
    updateQuantity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateQuantity() {
    axios
      .get(
        `https://api.stripe.com/v1/products/${process.env.REACT_APP_STRIPE_PRODUCT_KEY}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          },
        }
      )
      .then((response) => {
        const maxQuantity = response.data.metadata.maxAmount;
        setMaxQuantity(maxQuantity);
      })
      .catch((error) => console.log(error));
  }

  return (
    <div>
      <NavBarHome />
      <div className="section-gradients" id="home">
        <div className="gradient-wrapper">
          <div className="main-shapes-wrapper">
            <div className="shape-3" />
            <div className="shape-2" />
            <div className="shape-1" />
          </div>
          <div className="blending-group-wrapper">
            <div className="shape-8" />
            <div className="shape-9" />
            <div className="shape-4" />
            <div className="shape-5" />
            <div className="shape-7" />
            <div className="shape-6" />
          </div>
          <div className="background-noise" />
        </div>
        <div className="gradient-intro-wrapper">
          <div className="container-content gutter-outside">
            <div className="text-center">
              <h1 className="text-intro-title">
                Take your trading to the next level.
              </h1>
            </div>
            <div className="button-center">
              <Link
                to="introduction"
                className="link-intro inline-block"
                spy={true}
                smooth={true}
                duration={750}
              >
                <div className="text-below-intro">Learn more</div>
                <img
                  src={arrowDown}
                  loading="lazy"
                  alt="arrow down white"
                  className="image-arrow-below-intro"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div id="introduction" className="section-white">
        <div className="container-content gutter-outside">
          <div className="text-top-title-blue align-right">
            tailormade coaching
          </div>
          <div className="layout-grid grid-introducing">
            <h2 className="text-title-black align-right">
              Introducing 1-on-1 coaching
            </h2>
            <div>
              <p id="text-intro" className="paragraph black">
                A 1-on-1 coaching experience will provide you with personal
                coaching and guidance to help you improve your trading skills
                and strategies. Using my extensive knowledge and experience in
                trading, I will share insights and offer support tailored to
                your unique needs and objectives. You will get individualized
                help to understand markets dynamics better, identify potential
                trading opportunities, improve your risk management{" "}
                <Link
                  to="benefits"
                  className="link-introduction-text"
                  spy={true}
                  smooth={true}
                  duration={750}
                >
                  <strong>and more</strong>
                </Link>{" "}
                while receiving continuous feedback to help identify potential
                issues and bottlenecks with your past trades.
              </p>
              <div className="button-center">
                <Link
                  to="pricing"
                  className="link-intro inline-block"
                  spy={true}
                  smooth={true}
                  duration={750}
                >
                  <button className="submit-button-form submit-button not-found max-w">
                    Join now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="benefits" className="section-black blue-blob-medium">
        <div className="container-content gutter-outside">
          <div className="text-top-title-blue">Service content</div>
          <div className="text-center">
            <h2 className="text-title-white">What you can expect</h2>
          </div>
          <div className="layout-grid grid-benefits">
            <BenefitCard
              id="technical-benefit"
              title="Technical analysis"
              text="Increase knowledge about technical analysis (TA) and how to
             implement and execute TA to better your trades"
            />
            <BenefitCard
              id="review-benefit"
              title="Review &amp; feedback"
              text="Review and receive real-time feedback on previous trades and
             future plans"
            />
            <BenefitCard
              id="bottlenecks-benefit"
              title="Identify bottlenecks"
              text="Help to identify possible bottlenecks or obstacles in your trades that have
             been slowing you down"
            />
            <BenefitCard
              id="risk-benefit"
              title="Risk management"
              text="Develop and execute proper risk management strategies"
            />
            <BenefitCard
              id="leverage-benefit"
              title="Leverage trading"
              text="Learn how to use leverage to maximize profits and minimize risk"
            />
            <BenefitCard
              id="order-benefit"
              title="Order types"
              text="Learn about the importance of different order types, making
             trading more effective and less emotional"
            />
            <BenefitCard
              id="psychology-benefit"
              title="Psychology &amp; emotions"
              text="Understand market psychology to eliminate emotional repercussions and avoid
             market traps"
            />
            <BenefitCard
              id="experience-benefit"
              title="Share experiences"
              text="Share useful experiences from my trading career to help provide a fuller picture"
            />
          </div>
          <div className="button-center">
            <Link
              to="pricing"
              className="link-intro inline-block"
              spy={true}
              smooth={true}
              duration={750}
            >
              <button className="submit-button-form submit-button not-found max-w">
                Get started
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div id="testimonials" className="section-dark-blue">
        <div className="margin-testimonials">
          <div class="left-text-block-testimonial">
            <div className="text-top-title-blue">Testimonials</div>
            <h2 className="text-title-white ">Hear it from others</h2>
            <div className="reviews-wrap">
              <a
                href="https://www.trustpilot.com/review/tradingcoachmichael.com"
                target="_blank"
                rel="noreferrer"
                className="trustpilot"
              >
                <strong className="paragraph">Trustpilot</strong>
                <img
                  src={stars}
                  loading="lazy"
                  alt="stars"
                  className="image-stars"
                />
              </a>
              <p className="paragraph">(7)</p>
            </div>
          </div>
          <Swiper
            style={{
              "--swiper-pagination-color": "#2d74f7",
              "--swiper-pagination-bullet-inactive-color": "#999999",
              "--swiper-pagination-bullet-inactive-opacity": "0.35",
              "--swiper-pagination-bullet-size": "10px",
              "--swiper-pagination-bullet-horizontal-gap": "6px",
            }}
            modules={[Pagination]}
            spaceBetween={16}
            pagination={{ clickable: true }}
            breakpoints={{
              1150: {
                slidesPerView: 3,
              },
            }}
          >
             <SwiperSlide>
              <QuoteCard
                text="I'm overall extremely happy with my experience with Michael so far. I have paid for multiple trading advice platforms before and nothing comes close to what Michael offers. The 1 on 1 helped me get info on areas I struggled with most and while I still have lots to learn I know I can rely on someone for advice. I feel more confident in my own trades and the potential profit you can make easily pays for the cost of the service."
                name="- Derek"
              />
            </SwiperSlide>
            <SwiperSlide>
              <QuoteCard
                text="Working with Michael has been a wonderful experience. I came to him a complete beginner and can’t believe how much I’ve learned already. His breadth of knowledge is very humbling as is his ability to patiently break things down in a way I can understand. He is an incredibly kind and gifted teacher and an absolute gem of a human. If you find yourself with the opportunity to work with him, consider yourself very lucky… I know I do."
                name="- Falon"
              />
            </SwiperSlide>
            <SwiperSlide>
              <QuoteCard
                text="I have worked closely with Michael for the past two years, and my rising knowledge and ability to trade have coincided with time spent asking
                  questions and listening to his advice. As a teacher, Michael is patient and always looking to help you learn, so you can develop your trading style
                   rather than just copying him. As a trader, he is highly dedicated to his craft and clients with unique setups that are easily digestible and highly effective.
                    As a coach, Michael is seemingly always available to chat or run through your plan, and he does so with such ease that you quickly realize how lucky you are
                     to have him as a person to learn from, and the sugar on top is that he is just a great guy and someone you can trust."
                name="- Josh"
              />
            </SwiperSlide>
            <SwiperSlide>
              <QuoteCard
                text="The best investment you can make is an investment in yourself, and I can personally say that working with Michael 1 on 1 has been my best investment made in 2023. Regardless of your previous trading experience, Michael will make sure to teach you everything you need to know about TA, how to execute proper risk management, provides feedback on trade setups/future plans and shows how you how to improve them going forward and he does all that in simple easy way to understand (his charts are seriously the cleanest charts you’ll ever see). Most importantly (my favorite part) is that he is always available to chat with and to answer any question/concern you have."
                name="- Husain"
              />
            </SwiperSlide>
            <SwiperSlide>
              <QuoteCard
                text="Engaging in this 1-on-1 coaching subscription was a game-changer. Michael's guidance is a treasure trove of insights, propelling your trading skills to celestial heights. His promptness in addressing queries was consistently remarkable.

                Moreover, the personalized approach tailored to my individual needs made the learning experience both enriching and enjoyable. I highly recommend this coaching subscription to anyone seeking substantial growth in their trading journey."
                name="- Mehdi"
              />
            </SwiperSlide>
            <SwiperSlide>
              <QuoteCard
                text="The day Michael started to coach me, changed my trading. It's a good feeling that there is someone who is able to answer your questions, explains general topics, and provides a personal experience. And all that in a pace and depth that is adopted to my individual needs. I personally appreciate the swiftly replies, the patience of Michael, and the fact that I've seen quick results which leads to motivation and willingness to improve further. It is incredible how fast Michael responds to questions, stays patient when you ask similar questions again, and i really love his ability to explain complex topics as simply as possible which makes it understandable for newbies. Without him, i would have definitely closed my 'trading career' due to lack of success. But if you are willing to learn, patient, try to implement what he is discussing with you, than you have a high possibility to convert to a successful trader step by step. If you want to start that journey, Michael is your men!"
                name="- Andreas"
              />
            </SwiperSlide>
            <SwiperSlide>
              <QuoteCard
                text="top News information, very simple and well-said explanations, analysis and strategy for opening trades. I like it, it's my style"
                name="- Catinean"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div id="dont-offer" className="section-white blob-haikei">
        <div className="container-content gutter-outside">
          <div className="text-center">
            <div className="text-top-title-blue">Stay up to date</div>
            <h2 className="text-title-black">Market updates</h2>
          </div>
          <div className="layout-grid grid-not">
            <StayUpdatedCard
              id={"calls-benefit"}
              title={"Trading plans"}
              text={`Gain access to detailed trading plans, each presenting various scenarios and accompanied by thorough, easy-to-understand explanations`}
            />
            <StayUpdatedCard
              id={"signals-benefit"}
              title={"Market updates"}
              text={`Receive comprehensive market updates, complete with insightful charts and thoughtfully curated analysis`}
            />
            <StayUpdatedCard
              id={"calls-benefit"}
              title={"Economic events"}
              text={`Stay informed via timely updates, and deepen your understanding of vital economic events such as the Federal Open Market Committee (FOMC) meetings and Consumer Price Index (CPI) fluctuations`}
            />
          </div>
        </div>
        <div className="button-center">
          <Link
            to="pricing"
            className="link-intro inline-block"
            spy={true}
            smooth={true}
            duration={750}
          >
            <button className="submit-button-form submit-button not-found max-w">
              Join now
            </button>
          </Link>
        </div>
      </div>
      <div className="NFA">
        <p className="NFA-p">
          None of the content discussed in the service should be considered as
          financial advice. Please visit the{" "}
          <RouterLink
            to="/terms-of-service"
            className="NFA-terms-of-service-link"
            target="_blank"
          >
            Terms of Service
          </RouterLink>{" "}
          for more information.
        </p>
      </div>
      <div id="how" className="section-black blue-blob-small">
        <div className="container-content gutter-outside">
          <div className="content-how">
            <div className="text-top-title-blue longer">
              Exclusive. Different. Unrivaled.
            </div>
            <h2 className="text-title-white">How it works</h2>
            <div className="layout-grid grid-how">
              <HowRow
                imageDivId="bubble-how-div"
                imageId="text-bubble-how"
                divCardId="communication-how"
                image={textBubble}
                text="Communication will be done via Direct Messages (DM) on Discord"
              />
              <HowRow
                imageDivId="brush-how-div"
                imageId="brush-how"
                divCardId="personalized-how"
                image={brush}
                text="Focused on providing a personalized experience tailored to your specific needs and objectives."
              />
              <HowRow
                imageDivId="clock-how-div"
                imageId="clock-how"
                divCardId="anytime-how"
                image={clock}
                text="Ask me anything, anytime"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="pricing" className="section-white blue-blob-medium">
        <div className="container-content gutter-outside">
          <div className="text-top-title-blue">Are you ready?</div>
          <h2 className="text-title-black">Pricing plan</h2>
          <div className="content-pricing">
            <CheckoutCard />
          </div>
          <div className="wraper-text-availability">
            <p className="title-limited">
              Limited stock<span className="span-star-text">*</span>
            </p>
            <p className="paragraph black pricing" id="text-limited">
              I am limiting my 1-on-1 coaching service to {maxQuantity}{" "}
              subscriptions, in order to ensure a high level of quality. This
              will allow me to give individualized attention and support to my
              clients and manage my time effectively. I believe this approach
              will lead to better outcomes and a more fulfilling experience for
              both my clients and myself. Thank you for considering this limited
              offering and the opportunity to work with me in a more intimate
              and personalized setting.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
