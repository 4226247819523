import '../index.css'
import check from "../assets/check-icon.png";

function stayUpdatedCard({ id, title, text }) {
  return (
    <div id={id} className="card">
      <div className="content">
        <div className="header">
          <img
            src={check}
            loading="lazy"
            width={30}
            alt="cross-icon"
            className="image-card"
          />
          <h4 className="heading center white">{title}</h4>
          <p className='paragraph white center'>{text}</p>
        </div>
      </div>
    </div>
  );
}
export default stayUpdatedCard;
