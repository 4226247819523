import "../index.css";
import { Link } from "react-router-dom";

function NavItemOther({ to, text }) {
  return (
    <Link
      className="header-link inline-block"
      to={to}
    >
      <div className="header-text">{text}</div>
    </Link>
  );
}
export default NavItemOther;
